@import "../../../styles/constants.scss";
@import "../../../styles/colors.scss";

.nextCar {
  width: 100%;
  padding-bottom: 10px;

  font-family: "Open Sans";
  font-weight: 600;

  @media screen and (max-width: $mobile-screen) {
    h1 {
      max-width: 200px;
      margin: auto;
    }
  }

  .vehicles, .holder {
    min-height: 560px;

    @media screen and (max-width: 636px) {
      min-height: unset;
    }
  }

  .holder {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 636px) {
      height: 400px;
    }

    .errorMessage {
      font-size: 30px;

      @media screen and (max-width: $mobile-screen) {
        font-size: 22px;
        font-weight: 400;
      }
    }
  }

  .vehicles {
    width: 100vw;
    overflow: hidden;

    .vehicleCarousel {
      margin-top: 32px;
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      height: 419px;

      .vehicleImage {
        cursor: pointer;
      }

      @media screen and (max-width: $small-screen) {
        margin-top: 25px;
        height: 320px;

        .vehicleImage {
          width: 575px !important;
        }
      }
      @media screen and (max-width: 636px) {
        .vehicleImage {
          width: 90vw !important;
        }
      }
      @media screen and (max-width: $mobile-screen) {
        height: 250px;
      }
  
      .vehicleImage {
        width: 672px;
        height: 419px;
  
        background-repeat: no-repeat;
        background-position: center;
        background-size: 110%;

        opacity: 1;
        transform: translateX(0);

        @media screen and (max-width: $small-screen) {
          height: 320px;
        }
        @media screen and (max-width: $mobile-screen) {
          height: 250px;
        }
      }

      .animationImage {
        cursor: pointer;
        opacity: 0.25;

        position: absolute;
        z-index: 1;
      }

      .hiddenImage {
        transition: none;
      }
      .imageNext, .imagePrev, .leftImageNext, .leftImagePrev, .rightImageNext, .rightImagePrev {
        transition: opacity .5s ease-in-out, transform .5s ease-in-out;
      } 

      .imageNext {
        transform: translateX(-50vw) !important;
        opacity: 0.25;
      }
      .imagePrev {
        transform: translateX(50vw) !important;
        opacity: 0.25;
      }

      .leftImage {
        transform: translateX(-50vw);
      }
      .leftImageNext {
        transform: translateX(0) !important;
        opacity: 1;
      }
      .leftImagePrev, .leftHidden {
        transform: translateX(-100vw);
      }

      .rightImage {
        transform: translateX(50vw);
      }
      .rightImageNext {
        transform: translateX(0) !important;
        opacity: 1;
      }
      .rightImagePrev, .rightHidden {
        transform: translateX(100vw);
      }

      @media screen and (max-width: $small-screen) {
        .imageNext {
          transform: translateX(-60vw) !important;
        }
        .imagePrev {
          transform: translateX(60vw) !important;
        }

        .leftImage {
          transform: translateX(-60vw);
        }
        .rightImage {
          transform: translateX(60vw);
        }

        .leftImagePrev, .leftHidden {
          transform: translateX(-120vw);
        }
        .rightImagePrev, .rightHidden {
          transform: translateX(120vw);
        }
      }
      @media screen and (max-width: 840px) {
        .imageNext {
          transform: translateX(-120%) !important;
        }
        .imagePrev {
          transform: translateX(120%) !important;
        }

        .leftImage {
          transform: translateX(-120%);
        }
        .rightImage {
          transform: translateX(120%);
        }

        .leftImagePrev, .leftHidden {
          transform: translateX(-120%);
        }
        .rightImagePrev, .rightHidden {
          transform: translateX(120%);
        }
      }

      .arrowHolder {
        padding-top: 50px;
        cursor: pointer;
        
        flex: 1;
        height: 100%;
        
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          position: relative;
        }
        .leftArrow {
          left: 6vw;
          transform: rotate(180deg);
        }
        .rightArrow {
          right: 6vw;
        }

        @media screen and (max-width: $small-screen) {
          .leftArrow {
            left: 10vw;
          }
          .rightArrow {
            right: 10vw;
          }
        }
        @media screen and (max-width: 840px) {
          img {
            position: static;
          }
        }
      }

      @media screen and (max-width: 636px) {
        .arrowHolder {
          padding-top: 40px;

          flex: 0 !important;
          position: absolute;
          z-index: 5;

          width: 15vw;
          height: 40vh;

          img {
            height: 15px;
          }
        }

        .left {
          left: 0;
        }
        .right {
          right: 0;
        }
      }
      
    }

    .details {
      margin-top: 18px;
      cursor: pointer;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      .vehicleTitle {
        font-size: 38px;
        text-align: center;
      }

      .vehicleInfo {
        margin-top: 48px;
        font-size: 22px;
        text-align: center;
      }

      @media screen and (max-width: $small-screen) {
        .vehicleTitle {
          font-size: 32px;
        }
  
        .vehicleInfo {
          margin-top: 34px;
          font-size: 17px;
        }
      }
    }

    .controls {
      margin-top: 47px;
      
      display: flex;
      justify-content: center;

      @media screen and (max-width: $small-screen) {
        margin-top: 35px;
      }
    }
  }
}